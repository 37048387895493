
export class JudicialReviewPagedSearch {
    public pageNumber: number;
    public pageSize: number;
    public searchTerm: string;
    public orderBy: string;
    public sortDirection: string;
    public contraventionTypeId: number;
    public checkStayOrder: number;   
    public judicialReviewDecisionTypeIds: string; 

    constructor(init?: Partial<JudicialReviewPagedSearch>) {
        
        Object.assign(this, init);
    }
}
