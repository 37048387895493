import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@apis/shared/shared.module';
import { LawEnforcementSubmissionsComponent } from './law-enforcement-submissions.component';
import { SupportingDocumentsModule } from '../supporting-documents/supporting-documents.module';

@NgModule({
  declarations: [
    LawEnforcementSubmissionsComponent 
  ],
  imports: [
    CommonModule,
    SharedModule,
    SupportingDocumentsModule
  ],
  exports: [
    LawEnforcementSubmissionsComponent
  ]
})
export class LawEnforcementSubmissionsModule { }