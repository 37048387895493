import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
import { DatePipe } from "@angular/common";
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { RequestStatusType } from '@apis/shared/models/types/request-status-type.model';
import { RequestService } from '../shared/services/request.service';
import { EventTypes, RepresentationTypes, RequestTypes, SeizureTypes } from '@apis/shared/enums/app.enum';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { Event } from '@apis/shared/models/event.model';
import { EventService } from '@apis/shared/services/event.service';
import { LateReviewRequest } from '@apis/shared/models/late-review-request.model';
import { NoteModalComponent } from '@apis/shared/components/modals/note-modal/note-modal.component';
import { Document } from '@apis/shared/models/document.model';
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from "file-saver";
import { NgxSpinnerService } from 'ngx-spinner';
import { DeleteNoteModalComponent } from '@apis/shared/components/modals/delete-note-modal/delete-note-modal.component';
import { KeycloakService } from 'keycloak-angular';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { Constants } from "@apis/shared/helpers/constants";
import { LawEnforcementSubmissionsComponent } from '../shared/components/law-enforcement-submissions/law-enforcement-submissions.component';
import { RequestActivityComponent } from './components/request-activity/request-activity.component';
import { NoticeCancellationRequest } from '@apis/shared/models/notice-cancellation-request.model';
import { AssignAdjudicatorModalComponent } from './modals/assign-adjudicator-modal/assign-adjudicator-modal.component';
import { ChangeContactMethodModalComponent } from './modals/change-contact-method-modal/change-contact-method-modal.component';
import { CancelRequestModalComponent } from './modals/cancel-request-modal/cancel-request-modal.component';
import { ContactAddress } from '@apis/shared/models/contact-address.model';
import { LateReviewRequestItem } from '@apis/shared/models/late-review-request-item.model';
import { ContraventionType } from '@apis/shared/models/types/contravention-type.model';
import { SeizureType } from '@apis/shared/models/types/seizure-type.model';
import { AdjudicationService } from '../shared/services/adjudication.service';
import { Contravention } from '@apis/shared/models/contravention.model';
import { StopInformation } from '@apis/shared/models/stop-information.model';

declare var $: any

@Component({
  selector: 'app-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.scss']
})
export class RequestDetailsComponent implements OnInit {
  requestId: number;
  contraventionTypeId: number;
  seizureTypeId: number;
  requestNumber: string;
  requestType: any;
  request: any;
  supportingDocuments: any;
  events: Event[];
  requestStatusTypes: RequestStatusType[] = [];
  contraventionTypes: ContraventionType[];
  seizureTypes: SeizureType[];
  SeizureTypes = SeizureTypes;
  requestNotes: string;
  applicantName: string;

  bodyElement: JQuery<HTMLElement>;
  noteModalOverlay: JQuery<HTMLElement>;
  maxCharacters: number = 250;
  noteModalTextarea: JQuery<HTMLElement>;
  datePipe: DatePipe;

  TypeTable = TypeTable;
  recentActivities: any[] = [];
  EventTypes = EventTypes;
  loggedUserName: string;
  canUpdateContactMethod: boolean =false;
  canCancelRequest:boolean=false;
  canAssignAdjudicator:boolean=false;
  canApproveOrDenyLEARequest:boolean=false;
  Resource: any= Constants.Resource;
  Permission: any = Constants.Permission;
  requestDateTime:Date;
  adjudicatorName:string;
  contravention: Contravention;
  stopInformation: StopInformation;
  isLeaSubmissionsActive: boolean = false;
 
  RequestTypes = RequestTypes;
  manageRequestDropdown: HTMLSelectElement;  

  lateReviewRequestItems:LateReviewRequestItem[];

  @ViewChild("leaSubmissions") leaSubmissionsComponent: LawEnforcementSubmissionsComponent;
  @ViewChild("requestActivity") requestActivityComponent: RequestActivityComponent;
  
  constructor(private readonly activatedRoute: ActivatedRoute,
    private readonly requestService: RequestService,
    private readonly eventService: EventService,
    private readonly documentService: DocumentService,
    private readonly viewContainerRef: ViewContainerRef,
    readonly localStorageService: LocalStorageService,
    private readonly spinner: NgxSpinnerService,
    private readonly keycloakService: KeycloakService,
    private readonly adjudicationService: AdjudicationService) { }

  ngOnInit(): void {    
    this.bodyElement = $(document.body);
    this.noteModalOverlay = $(".note-modal-overlay");
    this.noteModalTextarea = $(".note-modal-textarea");        
    this.datePipe = new DatePipe("en-US");
    this.manageRequestDropdown = (document.getElementById("manageRequest") as HTMLSelectElement);
    this.requestStatusTypes = this.localStorageService.getRequestStatusTypes();                            
    this.contraventionTypes = this.localStorageService.getContraventionTypes();
    this.seizureTypes = this.localStorageService.getSeizureTypes();

    this.loggedUserName = this.keycloakService.getUsername();

    this.activatedRoute.params.subscribe((data: Params) => {
      this.requestNumber = data.requestNumber;
      this.activatedRoute.queryParams.subscribe((params) => {
        this.requestType = params['type'];        
        this.loadRequest();                    
      });
    });

    this.localStorageService.hasLocalStorageReady$.subscribe(val => {
      this.canUpdateContactMethod = this.localStorageService.hasPermission(Constants.Resource.REQUEST, Constants.Permission.UPDATE_CONTACT_METHOD);
      this.canCancelRequest = this.localStorageService.hasPermission(Constants.Resource.REQUEST, Constants.Permission.CANCEL_REQUEST);
      this.canAssignAdjudicator = this.localStorageService.hasPermission(Constants.Resource.REQUEST, Constants.Permission.ASSIGN_ADJUDICATOR);
      this.canApproveOrDenyLEARequest = this.localStorageService.hasPermission(Constants.Resource.POLICE_INTAKE_CANCELLATION_REQUEST, Constants.Permission.APPROVE_OR_DENY_REQUEST);
    });
  }

  loadRequest() {     
    this.spinner.show();       
    if(this.requestType == RequestTypes.LEACancellationRequest)
    {
      this.requestService.openNoticeCancellationRequest(this.requestNumber)
        .subscribe((result: NoticeCancellationRequest) => {
          this.requestId = result.noticeCancellationRequestId;
          this.request = result;  
          this.loadRequestDetails();
          this.spinner.hide();
        }, error => this.spinner.hide());
    } 
    else {
      this.requestService.openLateReviewRequest(this.requestNumber)
        .subscribe((result: LateReviewRequest) => {
          this.requestId = result.lateReviewRequestId;
          this.request = result;            
          this.isLeaSubmissionsActive = new Date(this.request.requestDate) >= new Date(+Constants.Adjudication.LATE_REVIEW_REQUEST_LEA_SUBMISSIONS_EFFECTIVE_DATE.substring(0, 4), +Constants.Adjudication.LATE_REVIEW_REQUEST_LEA_SUBMISSIONS_EFFECTIVE_DATE.substring(5, 7)-1, +Constants.Adjudication.LATE_REVIEW_REQUEST_LEA_SUBMISSIONS_EFFECTIVE_DATE.substring(8, 10));

          this.loadRequestDetails();
          this.spinner.hide();
        }, error => this.spinner.hide());
    }
  }

  getLateReviewRequestItems()
  {
    this.requestService.getLateReviewRequestItems(this.requestId)
    .subscribe((result: any[]) => {
      this.request.lateReviewRequestItems = result;

      // Get LEA Submissions if this is a contravention late review request. (Contravention only needs to be loaded once. Re-loading it causes errors in LawEnforcementSubmissionsComponent.)
      if (this.requestType == RequestTypes.LateReview && result.length > 0 && !this.contravention)
      {
        this.adjudicationService.getContraventionByNumber(result[0].recordNumber)
        .subscribe((result: Contravention) => {
          this.contravention = result;

          if (this.isLeaSubmissionsActive) {
            this.adjudicationService.getStopInformation(this.contravention.contraventionNumber)
            .subscribe((stopInformation: StopInformation) => {
              this.stopInformation = stopInformation;
            });
          }
        }, (error: any) => this.spinner.hide());   
      }

    }, (error: any) => this.spinner.hide());  
  }

  loadRequestDetails() {             
    if(this.requestType == RequestTypes.LEACancellationRequest)
    {
      this.applicantName = `${this.request.requestingOfficer.firstName} ${this.request.requestingOfficer.lastName}`;
    }
    else
    {
      this.manageRequestDropdown.selectedIndex = 0;
      this.getLateReviewRequestItems();

      // Generate name
      this.applicantName = `${this.request.driverFirstName} ${this.request.driverLastName}`;
    }

    this.contraventionTypeId = this.request.contraventionTypeId;
    this.seizureTypeId = this.request.vehicleSeizureTypeId;
    
    this.requestDateTime = new Date(this.request.requestDate.toString().replace(/[zZ]/, ""));
    this.requestDateTime.setMinutes(this.requestDateTime.getMinutes() - this.requestDateTime.getTimezoneOffset());

    if(this.request.adjudicatorFirstName && this.request.adjudicatorLastName)
    {
      this.adjudicatorName = `${this.request.adjudicatorFirstName} ${this.request.adjudicatorLastName}`;
    } 
    this.populateRecentActivity();  
  }

  populateRecentActivity(): void {
    this.recentActivities = [];
        
    let events = this.request.events;

    for (let event of events) {    
      let eventDateTime = new Date(event.eventDate.toString().replace(/[zZ]/, ""));      
      eventDateTime.setMinutes(eventDateTime.getMinutes() - eventDateTime.getTimezoneOffset());

      let eventDate = this.datePipe.transform(eventDateTime, "mediumDate");
      let eventTime = this.datePipe.transform(eventDateTime, "HH:mm");
      let username = event.userName;
      let title = this.localStorageService.getTypeItemDescriptionById(+event.eventTypeId, TypeTable.EventType)
      let body = event.eventDetails;

      this.recentActivities.push({
        date: `${eventDate} at ${eventTime}`,
        dateTime: eventDateTime,
        by: username,
        title: title,
        body: body,
        documents: event.documents,
        id: event.eventId,
        isCreatedByCaseAdministrator: event.isCreatedByCaseAdministrator
      });
    }      

    this.recentActivities = this.recentActivities.sort((a: any, b: any) => {
      if (a.dateTime > b.dateTime) return -1;      
      if (b.dateTime > a.dateTime) return 1;
      return 0;
    });

    this.recentActivities =  this.recentActivities.length > 3 ? this.recentActivities.slice(0,3) : this.recentActivities;
    
    setTimeout(() => {
      if ($(".recent-activity-container:last-child").length > 0) {
        let offsetTop = $(".recent-activity-container:last-child")[0].offsetTop;    
        $(".recent-activity-connector").height(offsetTop);
      }     
    }, 100);                
  }

  canModifyNote(recentActivity: any): boolean {
    return recentActivity.title == this.localStorageService.getTypeItemDescriptionById(EventTypes.NoteAdded, TypeTable.EventType)
      && (recentActivity.by == this.loggedUserName
      // Allow Case Coordinator to delete notes created by Case Administrator
      || (recentActivity.isCreatedByCaseAdministrator && this.keycloakService.isUserInRole(Constants.Role.CASE_COORDINATOR)));
  }

  onTabHeaderClick(ev: any): void {    
    if (ev.target.classList.contains("selected")) {
      return;
    }
   
    let tabId = ev.target.id.split("_")[1];

    $(".tab-header-item").removeClass("selected");
    $(".tab-content-item").removeClass("selected");
    
    $(`#tabHeader_${tabId}`).addClass("selected");
    $(`#tab_${tabId}`).addClass("selected");
  }

  onAddNoteLinkClick(): void {
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(NoteModalComponent);

    if(this.requestType == RequestTypes.LEACancellationRequest)
    {
      componentRef.instance.noticeCancellationRequestId = this.requestId;   
    }
    else
    {
      componentRef.instance.lateReviewRequestId = this.requestId;     
    }      
    componentRef.instance.documentRefTypeNumber = this.requestNumber;
    componentRef.instance.close.subscribe((result: Event) => {
      if (result != null) {
        this.request.events.push(result);
        this.populateRecentActivity();
        if(this.requestType != RequestTypes.LEACancellationRequest)
        {
          this.requestActivityComponent.populateRecentActivity();
        }
      }
      this.viewContainerRef.clear();
    });
  }  
  
  onEditNoteLinkClick(eventId: number): void {    
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(NoteModalComponent);
    componentRef.instance.documentRefTypeNumber = this.requestNumber;
    componentRef.instance.eventId = eventId;

    componentRef.instance.close.subscribe((result: Event) => {
      if (result != null) {
        this.request.events = this.request.events.filter(x=>x.eventId !== eventId);
        this.request.events.push(result);
        this.populateRecentActivity();
        if(this.requestType != RequestTypes.LEACancellationRequest)
        {
          this.requestActivityComponent.populateRecentActivity();
        }       
      }
      this.viewContainerRef.clear();
    });
  }

  onDeleteNoteLinkClick(eventId: number): void {    
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(DeleteNoteModalComponent);
    componentRef.instance.documentRefTypeNumber = this.requestNumber;
    componentRef.instance.eventId = eventId;

    componentRef.instance.close.subscribe((result: any) => {
      if (result) {
        this.request.events = this.request.events.filter(x=>x.eventId !== eventId);
        this.populateRecentActivity();
        if(this.requestType != RequestTypes.LEACancellationRequest)
        {
          this.requestActivityComponent.populateRecentActivity();
        }       
      }
      this.viewContainerRef.clear();
    });
  }

  onEventDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;    
    this.documentService.downloadDocument("", "Events", this.requestNumber, storageFileName, document.documentName)
      .subscribe((result: Blob) => {          
          fileSaver.saveAs(result, document.documentName);          
        },
        (error: any) => {});
  }

  onViewReceiptClicked() {
    this.spinner.show();
    this.requestService.getFeePaymentReceipt(this.requestNumber)
    .subscribe((result: any) => {
      if (result)
      { 
        fileSaver.saveAs(new Blob([result]), `Late Review Request - ${this.requestNumber} - Payment Receipt.pdf`);
      }
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
    });
  }

  onManageRequestChange(ev: any): void {
    const selectedOption = ev.target.selectedOptions[0].innerHTML.toLowerCase();

    switch (selectedOption) {
      case "assign to":        
        this.showAssignAdjudicatorModal();
        break;
      case "change contact method":
        this.showChangeContactMethodModal();
        break;      
      case "cancel request":
        this.showCancelRequestModal();
        break;
    }  
  }

  showAssignAdjudicatorModal(): void {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(AssignAdjudicatorModalComponent);
    
    componentRef.instance.request = Object.assign({}, this.request);
    componentRef.instance.requestType = Object.assign({}, this.requestType);
    componentRef.instance.close.subscribe((result: any) => {
      this.viewContainerRef.clear();
      this.manageRequestDropdown.selectedIndex = 0;
      if (result != null) {    
        this.request = result;        
        this.loadRequestDetails();
      }      
    });    
  }  

  showChangeContactMethodModal(): void {
    this.viewContainerRef.clear();        
    const componentRef = this.viewContainerRef.createComponent(ChangeContactMethodModalComponent);
    let newRequest = Object.assign({}, this.request);;
    
    if (newRequest.contactAddress == null) {
      newRequest.contactAddress = new ContactAddress();
    }

    componentRef.instance.request = newRequest;  
    componentRef.instance.requestType = this.requestType; 
    componentRef.instance.close.subscribe((result: any) => {
      this.viewContainerRef.clear();
      this.manageRequestDropdown.selectedIndex = 0;
      if (result != null) {        
        this.request = result;        
        this.loadRequestDetails();
      }      
    });
  }

  showCancelRequestModal(): void {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(CancelRequestModalComponent);    
    componentRef.instance.request = Object.assign({}, this.request);
    componentRef.instance.requestType = Object.assign({}, this.requestType);

    componentRef.instance.close.subscribe((result: any) => {
      this.viewContainerRef.clear();
      this.manageRequestDropdown.selectedIndex = 0;
      if (result != null) {
        this.request = result;        
        this.loadRequestDetails();
      }
    });
  }

  getNoticeType() {
    if (this.contraventionTypeId != null) {
      return this.contraventionTypes?.find(c => c.id == this.contraventionTypeId).name;
    }
    else {
      if (this.seizureTypeId != null) {
        return this.seizureTypes?.find(c => c.id == this.seizureTypeId).description;
      }
    }
  }

  requestActivityTabSelected() {
    this.requestActivityComponent.setOffSetTopForRequestRecentActivity();
  }
  
  refreshRequest(request: any) {        
    this.request = request;  
    this.loadRequestDetails();
  }
}
