import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ReviewDetailsComponent } from './review-details.component';
import { ApplicantSubmissionsComponent } from './components/applicant-submissions/applicant-submissions.component';
import { DecisionComponent } from './components/decision/decision.component';
import { ReviewComponent } from './components/review/review.component';
import { ReviewActivityComponent } from './components/review-activity/review-activity.component';
import { SharedModule } from '@apis/shared/shared.module';
import { ApplicantAvailabilityModalComponent } from './modals/applicant-availability-modal/applicant-availability-modal.component';
import { ChangeReviewStatusModalComponent } from './modals/change-review-status-modal/change-review-status-modal.component';
import { SubmitDecisionModalComponent } from './components/decision/modals/submit-decision-modal/submit-decision-modal.component';
import { DocumentUploadComponent } from "./components/document-upload/document-upload.component";
import { ReassignAdjudicatorModalComponent } from './modals/reassign-adjudicator-modal/reassign-adjudicator-modal.component';
import { ChangeContactMethodModalComponent } from './modals/change-contact-method-modal/change-contact-method-modal.component';
import { ChangeDecisionDueDateModalComponent } from './modals/change-decision-due-date-modal/change-decision-due-date-modal.component';
import { CancelReviewModalComponent } from './modals/cancel-review-modal/cancel-review-modal.component';
import { SupportingDocumentsModule } from '../shared/components/supporting-documents/supporting-documents.module';
import { ChangeReviewMethodModalComponent } from "./modals/change-review-method-modal/change-review-method-modal.component";
import { RescheduleReviewModalComponent } from "./modals/reschedule-review-modal/reschedule-review-modal.component";
import {JudicialReviewServedModalComponent} from "./modals/judicial-review-served-modal/judicial-review-served-modal.component";
import { ReviewCancellationReasonComponent } from './components/review-cancellation-reason/review-cancellation-reason.component';
import { ReviewLastViewedModalComponent } from './modals/review-last-viewed-modal/review-last-viewed-modal.component';
import { DriverLogModule } from '../shared/components/driver-log/driver-log.module';
import { LawEnforcementSubmissionsModule } from '../shared/components/law-enforcement-submissions/law-enforcement-submissions.module';

@NgModule({
  declarations: [
    ReviewDetailsComponent,
    ApplicantSubmissionsComponent,
    DecisionComponent,
    ReviewComponent,
    ReviewActivityComponent,
    ApplicantAvailabilityModalComponent,        
    ChangeReviewStatusModalComponent, 
    SubmitDecisionModalComponent,
    DocumentUploadComponent,
    ReassignAdjudicatorModalComponent,
    ChangeContactMethodModalComponent,
    ChangeDecisionDueDateModalComponent,
    CancelReviewModalComponent,
    ChangeReviewMethodModalComponent,
    RescheduleReviewModalComponent,
    JudicialReviewServedModalComponent,
    ReviewCancellationReasonComponent,
    ReviewLastViewedModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SupportingDocumentsModule,
    DriverLogModule,
    LawEnforcementSubmissionsModule
  ]
})
export class ReviewDetailsModule { }
